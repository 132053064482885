import { AuthConfig } from 'angular-oauth2-oidc';
 

export const authConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: 'https://apidev.cemfalcon.com:11000',
  loginUrl: 'https://apidev.cemfalcon.com:11000/connect/authorize',
  tokenEndpoint: 'https://apidev.cemfalcon.com:11000/connect/token',
  // sessionCheckIFrameUrl: 'https://localhost:11000/connect/checksession',
  // userinfoEndpoint: 'https://localhost:11000/connect/userinfo',
  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + '/signin-oidc', 
  postLogoutRedirectUri: window.location.origin + '/signout-callback-oidc', 
  // customQueryParams: {
  //   "response_mode": "query",
  // },
  oidc: true, 
  // The SPA's id. The SPA is registered with this id at the auth-server
  clientId: 'mvc', 
  // set the scope for the permissions the client should request
  // The first three are defined by OIDC. The 4th is a usecase-specific one
  scope: 'openid api1 offline_access roles profile',
  responseType: 'code', 
  // dummyClientSecret: 'secret', 
  strictDiscoveryDocumentValidation: false,
  skipIssuerCheck: true,
   requireHttps: false,
   showDebugInformation: true, 
  requestAccessToken: true,
  nonceStateSeparator: '&',
  dummyClientSecret: 'secret',
  logoutUrl: 'https://apidev.cemfalcon.com:11000/connect/endsession',
  clearHashAfterLogin: true,
  
  

}