export const surveyType = {
    Transactional: "TRANS",
    Distributor: "DIST",
    Common: "COMMON",
    Revenue: "REVENUE",
    REVENUE_CUST:"REVENUE_CUST",
    // LostCustomer:"LostCustomer"
    LostCustomer:"LOSTCUST",
    OEM:'OEM'
}

export const widgetCodes = {
    CountryComparision: "CC",
    InfluencerAnalysis: "IA",
    LoyaltyShiftDrillDown: "LSDD",
    NetPromoterScoreTrend: "NPST",
    ResponseRateTrend: "RRT",
    StoryBoard: "SB",
    SurveySentVSResponseRecieved: "SSRR",
    TopFiveCustomers: "TOP5",
    CustomerFeedback: "CF",
    AverageRating: "AR",
    LoyaltyShift: "LS",
    ResponseRate: "RR",
    SurveyInvitationSent: "SIS",
    TopThreeQuestion : "TOP3",
    NetPromoterScoreByCountry: "NPSBC",
    TopTenDistributor: "TOP10",
    TopTenDistributorRevenue: "TOP10REV",
    NPS:"NPS",
    NSSC: "NSSC",
    DistributorFeedback: "DF",
    
    LostBusinessCustomer: "LBC",
    LostBusinessCustomerMainWidget: "LBCM",
    NoResponseByRevenue: "NRR",
    RevenueAtRiskByNPS: "RRN",
    RevenueWiseSentimentComparison: "RSC",
    NetSentimentScore: "NSS",
    NPSNSSTrend: "NNT",
    SentimentCategories: "SC",
    WordCloud: "WC",
}
export const Configuration={
    APIURL: "https://apidev.cemfalcon.com:10001",
    IdentityURL: "https://apidev.cemfalcon.com:11000",
    BlobImageURL: null,
    ContainerName: "falconimguploadlocal",
    FolderName: "userdp",
    ContactUsEmail: "customerloyalty@atlascopco.com",
    MessageTimeOut: 10000,
    baseUrl: "https://apidev.cemfalcon.com/",
    dragDropTimeOut: 5000,
    CustomerSupportEmailID: "customerloyalty@atlascopco.com",
    AccessDeniedUrl: "error/accessdenied",
    ApiTimeOutInMinutes: 5,
    MaxLBRecordCount: "100000",
    UseGzippedPackages: "NO",
    DashboardImagePath: "https://falconuat1.blob.core.windows.net/falconuatimg/dashboard/",
    CTLSAPIURL: "https://apidev.cemfalcon.com:12001"
}

